import React from 'react';
import './App.css';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <h1 className="mt-4">
        <em>To My dearest wife, my beloved girl, my treasured best friend,</em>
      </h1>
      <img src="./heart.png" alt="heart" />
      <p>
        One year. 365 days. It’s hard to believe that an entire year has passed
        since we first embarked on this beautiful journey together. As I sit
        down to write this, I find myself overwhelmed with love, gratitude, and
        admiration for you and for everything we’ve shared.
      </p>
      <img src="./heart.png" alt="heart" />
      <br />
      <iframe
        src="https://giphy.com/embed/8heJ3EOjpsi8pJ5yia"
        style={{ border: 0, marginTop: "2vh" }}
        className="giphy-embed"
        allowFullScreen
        title="Animated Heart GIF"
      ></iframe>
      <p className="mt-3">
        From the very first moment we connected, I knew there was something
        special about you. You captivated me with your warmth, your
        intelligence, and that incredible smile that never fails to brighten my
        day. Over this past year, I’ve come to know you even more deeply, and
        with each passing day, my love for you has only grown stronger.
      </p>
      <img src="./heart.png" alt="heart" />
      <br/>
      <iframe className="mt-3"
        title="I wanna marry you"
        src="https://www.youtube.com/embed/QFs3PIZb3js?si=f6F4FVlqUqddRZJU&amp;controls=0&amp;start=38"
        allowFullScreen
      ></iframe>

      <p className="mt-3">
        Being in a long-distance relationship hasn’t always been easy. There
        have been times when the miles between us felt like an insurmountable
        distance, but through it all, you’ve been my constant, my rock, my safe
        place. Your strength, patience, and unwavering love have carried us
        through the toughest moments, and for that, I am eternally grateful.
      </p>
      <img src="./heart.png" alt="heart" />
      <p>
        I cherish the memories we’ve created together—every laugh, every
        conversation, every moment we’ve shared, whether near or far. Every
        moment, every progress we have, had, and will have are my treasure.
        Those moments are the ones that have made me fall in love with you more
        deeply than I ever thought possible.
      </p>
      <img src="./heart.png" alt="heart" />
      <br />
      <Carousel className="carousel" interval={1500} controls={false}>
        <Carousel.Item className="wrap">
          <img src="Cora 1.JPEG" alt="Cora" className="carousel-img" />
        </Carousel.Item>

        <Carousel.Item className="wrap">
          <img src="Cora 2.JPEG" alt="Cora" className="carousel-img" />
        </Carousel.Item>

        <Carousel.Item className="wrap">
          <img src="Cora 3.JPEG" alt="Cora" className="carousel-img" />
        </Carousel.Item>

        <Carousel.Item className="wrap">
          <img src="Cora 4.JPEG" alt="Cora" className="carousel-img" />
        </Carousel.Item>

        <Carousel.Item className="wrap">
          <img src="Cora 5.JPEG" alt="Cora" className="carousel-img" />
        </Carousel.Item>
      </Carousel>
      <br />
      <br />
      <p>
        You’ve shown me what it means to love someone truly, to care for them
        with every part of your being, even when you can’t be with them
        physically. You’ve taught me that love knows no bounds, that it
        transcends distance and time. Even though we’re apart, I feel your
        presence with me every day—in my thoughts, in my heart, in everything I
        do.
      </p>
      <img src="./heart.png" alt="heart" />
      <br />
      <iframe
        className="mt-4"
        title="Just a kiss from you will fix everything"
        src="https://www.youtube.com/embed/v_yTphvyiPU?si=394SuFYsIZTlL_dK"
        width="560"
        height="315"
        allowFullScreen
      ></iframe>

      <p className="mt-2">
        As we celebrate our one-year anniversary, I want you to know how much
        you mean to me. You are the light in my life, the reason I wake up every
        morning with a smile on my face. You are my best friend, my confidante,
        my partner in everything. I can’t imagine my life without you, and I
        wouldn’t want to. We have our downs but we will also have ups because we
        are balance.
      </p>
      <img src="./heart.png" alt="heart" />
      <p>
        This past year has been an incredible adventure, and I can’t wait to see
        where the next year takes us. I dream of the day when we can finally
        close the distance between us, when I can hold you in my arms and tell
        you in person how much I love you. Until that day comes, I want you to
        know that no matter where I am, no matter how far apart we may be, you
        are always in my heart.
      </p>
      <img src="./heart.png" alt="heart" />
      <p>
        Happy anniversary, my love. Thank you for being the most amazing person
        I’ve ever known. Thank you for loving me, for believing in us, and for
        making this past year the best year of my life. Here’s to many more
        years of love, laughter, and happiness together.
      </p>
      <br />
      <img src="./heart.png" alt="heart" />
      <img src="./heart.png" alt="heart" />
      <img src="./heart.png" alt="heart" />
      <p>
        With all my love,
        <br />
        <h2>
          <em>Your husband, Paul</em>
        </h2>
      </p>
      <iframe
        title="I found you"
        src="https://www.youtube.com/embed/GxldQ9eX2wo"
        width="560"
        height="315"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default App;
